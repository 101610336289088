import Chart from './Chart'

class LineChart extends Chart {
  constructor (ref, series, options) {
    super(ref, series)

    this.setOptions(this.lineChartOptions(options))

    this.init()
  }

  lineChartOptions ({ title, tooltipFormatter }) {
    let tooltip = {}

    if (tooltipFormatter) {
      tooltip.formatter = tooltipFormatter
    }

    return {
      chart: {
        type: 'line'
      },
      title: {
        text: title,
        align: 'left',
        style: {
          fontWeight: 'bold'
        }
      },
      yAxis: {
        min: 0,
        title: false
      },
      xAxis: {
        type: 'datetime'
      },
      tooltip,
      plotOptions: {
        series: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      }
    }
  }
}

export default LineChart
