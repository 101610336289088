<template>
  <div class="container p-b-xxl">
    <div class="columns">
      <div class="column">
        <heading>Demo Charts</heading>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Column chart</subheading>
        <div ref="columnchart" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Line chart</subheading>
        <div ref="linechart" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Area chart</subheading>
        <div ref="areachart" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Bar chart</subheading>
        <div ref="barchart" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Bar chart monochrome</subheading>
        <div ref="barchartmonochrome" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Donut chart</subheading>
        <div ref="donutchart" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Donut chart sentiment</subheading>
        <div ref="donutchartsentiment" class="chart" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <subheading>Map Chart</subheading>
        <div ref="mapchart" class="chart" />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@hypefactors/shared/js/components/charts/base/BarChart'
import LineChart from '@hypefactors/shared/js/components/charts/base/LineChart'
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import ColumnChart from '@hypefactors/shared/js/components/charts/base/ColumnChart'
import MapChart from '@hypefactors/shared/js/components/charts/base/MapChart'

import {
  moneyFormatter,
  moneyTimeseriesFormatter,
  moneyCategoryFormatter,
  moneyDonutFormatter
} from '@hypefactors/shared/js/components/charts/TooltipFormatter'

export default {
  data: () => ({
    $columnChart: null,
    $lineChart: null,
    $areaChart: null,
    $barChart: null,
    $barChartMonochrome: null,
    $donutChart: null,
    $donutChartSentiment: null,
    $mapChart: null
  }),

  mounted () {
    this.$columnChart = new ColumnChart(
      this.$refs.columnchart,
      this.randomDataForColumnChart(),
      {
        title: 'Hypefactors Value',
        tooltipFormatter: moneyTimeseriesFormatter
      }
    )

    this.$lineChart = new LineChart(
      this.$refs.linechart,
      this.randomDataForColumnChart(),
      {
        title: 'Hypefactors Value',
        tooltipFormatter: moneyTimeseriesFormatter
      }
    )

    this.$areaChart = new AreaChart(
      this.$refs.areachart,
      this.randomDataForColumnChart(),
      {
        title: { text: 'Hypefactors Value' },
        tooltipFormatter: moneyTimeseriesFormatter
      }
    )

    this.$barChart = new BarChart(
      this.$refs.barchart,
      ['Facebook', 'Twitter', 'LinkedIn', 'Instagram'],
      this.randomDataForBarChart(),
      {
        title: 'Hypefactors Value',
        formatter: moneyFormatter,
        tooltipFormatter: moneyCategoryFormatter
      }
    )

    this.$barChartMonochrome = new BarChart(
      this.$refs.barchartmonochrome,
      ['Denmark', 'Sweden', 'Norway', 'Germany', 'United Kingdom'],
      this.randomDataForBarChartMonochrome(),
      {
        title: 'Clippings',
        monochrome: true
      }
    )

    this.$donutChart = new DonutChart(
      this.$refs.donutchart,
      this.randomDataForDonutChart(),
      {
        title: '2.6 M DKK',
        subtitle: 'Hypefactors Value',
        formatter: moneyDonutFormatter
      }
    )

    this.$donutChartsentiment = new DonutChart(
      this.$refs.donutchartsentiment,
      this.randomDataForDonutChartSentiment(),
      {
        title: '70,33%',
        subtitle: 'Positive'
      }
    )

    this.$mapChart = new MapChart(
      this.$refs.mapchart,
      [{
        joinBy: ['iso-a2', 'code'],
        data: [
          { code: 'IS', value: 1 },
          { code: 'NO', value: 3 },
          { code: 'SE', value: 2 },
          { code: 'DK', value: 3 },
          { code: 'FI', value: 4 },
          { code: 'BG', value: 5 }
        ],
        name: 'Impressions'
      }],
      { title: 'World Map' }
    )
  },

  methods: {
    randomBetween (min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },

    randomHfValue () {
      return this.randomBetween(1000, 10000)
    },

    randomDataForColumnChart () {
      return [
        {
          data: [[1513814400000, this.randomBetween(2000, 5000)], [1513900800000, this.randomBetween(5000, 9000)]],
          name: 'Hypefactors'
        },
        {
          data: [[1513814400000, this.randomBetween(2000, 3000)], [1513900800000, this.randomBetween(1000, 9000)]],
          name: 'Foobar'
        }
      ]
    },

    randomDataForBarChart () {
      return [
        {
          data: [this.randomHfValue(), this.randomHfValue(), this.randomHfValue(), this.randomHfValue(), this.randomHfValue()],
          name: 'Hypefactors Value'
        }
      ]
    },

    randomDataForBarChartMonochrome () {
      return [
        {
          data: [this.randomHfValue(), this.randomHfValue(), this.randomHfValue(), this.randomHfValue(), this.randomHfValue()],
          name: 'Clippings'
        }
      ]
    },

    randomDataForDonutChart () {
      return [
        {
          data: [{ y: this.randomHfValue(), name: 'Print' }, { y: this.randomHfValue(), name: 'Online' }, {
            y: this.randomHfValue(),
            name: 'Tv'
          }, { y: this.randomHfValue(), name: 'Radio' }, { y: this.randomHfValue(), name: 'Social' }],
          name: 'Hypefactors Value'
        }
      ]
    },

    randomDataForDonutChartSentiment () {
      return [
        {
          data: [{ y: 10, name: 'Very positive' }, { y: 15, name: 'Positive' }, { y: 25, name: 'Neutral' }, { y: 30, name: 'Negative' }, {
            y: 20,
            name: 'Very negative'
          }],
          name: 'Sentiment'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
